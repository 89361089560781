import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

const RenderText = (props) => {
  const { data } = props
  var ts = new Date(data.wordpressWpSettings.buildDate);
  return (
    <>
    <Helmet>
      <meta name="Last-Modified" content={ts.toString()}/>
    </Helmet>
    <div>{ts.toString()}</div>
    </>
  );
}
export default RenderText;

export const pageQuery = graphql`
  query {
    wordpressWpSettings {
      buildDate
    }
  }
`
